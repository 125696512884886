//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import { FullModelView } from '@/components'
import billTable from './components/billTable.vue'
import receTable from './components/receTable.vue'
import sendTable from './components/sendTable.vue'
export default {
  name: 'StepsLabel',
  components: {
    FullModelView,
    sendTable,
    receTable,
    billTable,
  },
  data() {
    return {
      title: '詳情',
      subTitle: '',
      visible: false,
      officialId: '',
      submitLoading: false,
      type: '',
      formMessage: {
        name: '發文稿紙',
      },
    }
  },
  watch: {
    officialId: {
      handler(val) {
        val && this.getInfo()
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    open({ type, billId }) {
      this.officialId = billId
      this.visible = true
      this.type = type
    },
    getInfo() {
      this.form_id = this.officialId
      const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          const { data, success, message } = res
          if (!success || !data) {
            this.$message.error(message || '請求失敗，請聯系相關業務員')
            this.close()
            return
          }
          const {
            name,
            acceptTime,
            urgencyDegree,
            secrecyDegree,
            deviseOpinion,
            draftId,
            // approvalWay,
            tellPhone,
            summary,
            title,
            documentNodeList,
            documentFileList, //附件
            documentNumber,
            mainFile, //正文
            checkIds,
            draftCompany,
            outDraftDesc,
            outDraftCompany,
            meetCompany,
            meetDesc,
            templateId,
            approvalStatus,
            serialNumber,
            receiverCompany,
            id,
          } = res.data
          const form = {
            name,
            meetDesc,
            meetCompany,
            outDraftDesc,
            outDraftCompany,
            lwsj: acceptTime,
            jjcd: urgencyDegree ? String(urgencyDegree) : '',
            miji: secrecyDegree ? String(secrecyDegree) : '',
            nbyj: deviseOpinion,
            deviseOpinion,
            draftId,
            checkIds,
            draftCompany,
            title,
            nodes: documentNodeList,
            documentNodeList,
            tellPhone,
            attach: mainFile && [mainFile], //正文
            mainFile,
            fileIds: documentFileList, //附件
            summary,
            documentNumber,
            templateId,
            serialNumber,
            receiverCompany,
            id,
          }
          // console.log('formMessage+++++++++++', form)
          this.approvalStatus = approvalStatus
          //
          this.formMessage = form
          //
          this.fileIds = documentFileList
        })
      }
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      if (this.$route.query.id) {
        this.$router.go(-1)
      }
    },
  },
}

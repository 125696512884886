//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line camelcase
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { documentArchivedList, wxRealFile, wxTransform } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import moment from 'moment'
import { receivedColumns, sendingColumns } from './columns.js'
import detail from './detail.vue'
import { handleFile } from './minxins.js'

export default {
    name: 'list',
    mixins: [stateColor, handleFile],
    components: {
        STable,
        OpenData,
        detail,
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['收文', '發文'],
            btck: 1,
            loading: false,
            loadingFile: false,
            // 查詢參數
            //queryParam: { approvalStatus: 0, documentType: 1 },
            queryParam: {
                archivedType: 1,
            },
            // 表頭
            columns: receivedColumns,
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                const { archivedTime } = this.queryParam
                if (archivedTime) {
                    this.queryParam.startArchivedTime = archivedTime[0] && moment(archivedTime[0]).format('YYYY-MM-DD')
                    this.queryParam.endArchivedTime = archivedTime[1] && moment(archivedTime[1]).format('YYYY-MM-DD')
                    delete this.queryParam.archivedTime
                }
                this.selectedRowKeys = []
                this.selectedRows = []
                return documentArchivedList(Object.assign(parameter, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    if (data.rows) {
                        data.rows = data.rows.map((ele) => {
                            ele.archivedUser = formatDraftId(ele.archivedUser)
                            return ele
                        })
                    }
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool', '.big_title'], 110, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    watch: {
        '$route.query.index': {
            handler() {
                this.changeTabStatus(Number(this.$route.query.index) || 1)
            },
            immediate: true,
        },
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool', '.big_title'], 110)
    },
    methods: {
        handleOk() {},
        wxTransform(str) {
            this.$_loading.show('正在下載文件...')
            wxTransform({
                ids: str,
            })
                .then((res) => {
                    if (res.code === 200 && res.data) {
                        this.createNodeUploadFile(res.data)
                    }
                })
                .finally(() => {
                    this.loading = false
                    this.$_loading.hide()
                })
        },
        wxRealFile(ids) {
            this.$_loading.show('正在下載文件...')
            wxRealFile({
                ids,
            })
                .then((res) => {
                    if (res) {
                        this.handleFileFlow(ids)
                    }
                })
                .finally(() => {
                    this.loadingFile = false
                    this.$_loading.hide()
                })
        },
        download({ billId }) {
            this.wxTransform(billId)
        },
        downloadMany() {
            if (this.selectedRows.length > 20) return this.$message.warning('選擇的數據不能超過20條')
            if (this.loading) return
            this.loading = true
            this.wxTransform(this.selectedRows.map((j) => j.billId).join())
        },
        downloadFile({ billId }) {
            this.wxRealFile(billId)
        },
        downloadManyFile() {
            if (this.selectedRows.length > 20) return this.$message.warning('選擇的數據不能超過20條')
            if (this.loadingFile) return
            this.loadingFile = true
            this.wxRealFile(this.selectedRows.map((j) => j.billId).join())
        },
        createNodeUploadFile(url, fileName = 'fileName') {
            var downloadElement = document.createElement('a')
            downloadElement.style.display = 'none'
            downloadElement.href = url
            downloadElement.download = fileName
            document.body.appendChild(downloadElement)
            downloadElement.click() // 點擊下載
            document.body.removeChild(downloadElement) // 下載完成移除元素
            this.spinning = false
        },
        refresh() {
            this.$refs.table.refresh()
        },
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 獲取字典數據
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
            })
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        changeTabStatus(i) {
            this.queryParam = {}
            this.btck = i
            this.queryParam.archivedType = i
            if (+i == 1) {
                this.columns = receivedColumns
            } else {
                this.columns = sendingColumns
            }
            this.$refs.table && this.$refs.table.refresh(true)
        },
        change_status(i) {
            if (this.btck === i) return
            this.$router.push({
                query: {
                    index: i,
                },
            })

            // this.$refs.table.refresh(true)
        },
        see(record) {
            this.$refs.detailInfo.open({ ...record, type: record.documentType })
        },
        // 處理文件流
        handleFileFlow(ids) {
            try {
                let url = `${process.env.VUE_APP_API_BASE_URL}/wx/getRealFile?ids=${ids}`
                // let name = headers['content-disposition']
                // // let type = headers['content-type']
                // let fileName = this.getFileName(name)
                // let contentType = this.getFileContentTypeByFileName(fileName)
                // let blob = new Blob([resData], { type: `${contentType};charset=UTF-8` })
                // // 通過URL.createObjectURL生成文件路徑
                // let url = window.URL.createObjectURL(blob)
                this.createNodeUploadFile(url)
                window.URL.revokeObjectURL(url)
            } catch (error) {
                alert(error.message)
            }
        },
        getFileName(str) {
            if (!str) return
            const arr = str.split(';')
            // filename*=
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            let temp = arr.find((e) => filenameRegex.exec(e))
            if (temp) {
                let fileArr = temp.split('=')
                return fileArr[1].replace(/\"/g, '')
            }
            // filename=
            temp = arr.find((e) => e.startsWith('filename='))
            if (temp) {
                return temp.replace('filename=', '')
            }
            throw Error('未在響應頭獲取到文件名（請檢查響應頭字段大小寫），且默認自定義文件名未傳！！')
        },
    },
}

// 收文
export const receivedColumns = [
    {
        title: '辦文號',
        dataIndex: 'serialNumber',     
        ellipsis: true
    },
    {
        title: '標題',
        dataIndex: 'archivedTitle',
        scopedSlots: { customRender: 'docTitle' },
        ellipsis: true
    },
    {
        title: '來文字號',
        dataIndex: 'documentNumber',
        ellipsis: true
    },
    {
        title: '緊急程度',
        dataIndex: 'urgencyDegree',
        // width: 100,
        scopedSlots: { customRender: 'urgencyDegree' },
    },
    {
        title: '密級程度',
        dataIndex: 'secrecyDegree',
        // width: 100,
        scopedSlots: { customRender: 'secrecyDegree' },
    },
    {
        title: '類型',
        dataIndex: 'documentType',
        // width: 150,
        scopedSlots: { customRender: 'documentType' },
    },
    {
        title: '經辦人',
        dataIndex: 'archivedUser',
        // width: 120,
        scopedSlots: { customRender: 'archivedUser' },
    },
    {
        title: '歸檔時間',
        dataIndex: 'archivedTime',
        // width: 150,
        scopedSlots: { customRender: 'acceptTime' },
    },
    {
        title: '操作',
        // fixed:'right',
        dataIndex: 'action',
        // width: 240,
        scopedSlots: { customRender: 'action' },
    },
]

// 發文
export const sendingColumns = [
    {
        title: '發文號',
        dataIndex: 'documentNumber',
        ellipsis: true
    },
    // {
    //     title: '發文字號',
    //     dataIndex: 'documentNumber',
    //     ellipsis: true
    // },
    {
        title: '文稿標題',
        dataIndex: 'archivedTitle',
        ellipsis: true,
        scopedSlots: { customRender: 'docTitle' },
    },
    {
        title: '緊急程度',
        dataIndex: 'urgencyDegree',
        // width: 100,
        scopedSlots: { customRender: 'urgencyDegree' },
    },
    {
        title: '密級程度',
        dataIndex: 'secrecyDegree',
        // width: 100,
        scopedSlots: { customRender: 'secrecyDegree' },
    },
    {
        title: '類型',
        dataIndex: 'documentType',
        // width: 150,
        scopedSlots: { customRender: 'documentType' },
    },
    {
        title: '擬稿人',
        dataIndex: 'archivedUser',
        // width: 120,
        scopedSlots: { customRender: 'archivedUser' },
    },
    {
        title: '歸檔時間',
        dataIndex: 'archivedTime',
        // width: 150,
        scopedSlots: { customRender: 'acceptTime' },
    },
    {
        title: '操作',
        // fixed:'right',
        dataIndex: 'action',
        // width: 240,
        scopedSlots: { customRender: 'action' },
    },
]